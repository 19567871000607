.container {
  max-width: 960px;
}
* {
  color: #273B09;
}
/*
 * Cabeçalho translucente customizado
 */
 .justificar-texto{
  text-align: justify !important;
 }
 .site-header {
  background-color: rgba(123, 144, 75, .85);
 
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.nav-link {
  color: #742421 !important;
  transition: ease-in-out color .15s;
  text-decoration: none;
}

.nav-link:hover {
  color: #fff !important;
  text-decoration: none;
}

.nav-link.ativo {
  color: #fff !important;
  font-weight: bold; /* Optional: To make the ativo link stand out more */
}

.nav-link.ativo:hover {
  color: #fff !important;
  text-decoration: none; /* Ensuring hover doesn't alter the active link */
}


.site-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0iTTQgN2gyMk00IDE1aDIyTTQgMjNoMjIiLz48L3N2Zz4=");
}



.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }
